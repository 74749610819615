import { ApiId } from './api.types';

export type MockConfig = {
  mocks: Record<number, unknown>;
  status?: number;
  disabled?: boolean;
};

export type MocksConfig = Record<ApiId, MockConfig>;

export class MockError<T> extends Error {
  constructor(apiId: ApiId, status: number, data?: T) {
    super(data ? `apiId ${apiId} has status ${status}` : `apiId ${apiId} has no mock`);
  }
}
