import { Api, ApiId } from 'src/types/networking/api.types';
import { hasKeys, stringifyRecord } from 'src/utils/basic.utils';
import { injectPathParams, injectQueryParams } from 'src/utils/networking.utils';

import useMock from '../mock/useMock';
import useApiConfig from './useApiConfig';

export default function useApi<K extends ApiId>(apiId: K) {
  const apiConfig = useApiConfig(apiId);

  const { isMocked, getMock } = useMock<Api[K]['output']>(apiId);

  const prepareRequest = (input: Api[K]['input']) => {
    const { protocol, host, path, pathParams, queryParams, body, ...otherConfig } = {
      ...apiConfig,
      ...input,
    };
    const url = injectPathParams(
      pathParams,
      `${protocol}://${host}${path}${injectQueryParams(stringifyRecord(queryParams))}`
    );
    const config = hasKeys(body) ? { body: JSON.stringify(body), ...otherConfig } : otherConfig;
    return { url, config };
  };

  if (isMocked()) {
    return async (input: Api[K]['input']) => {
      const { url, config } = prepareRequest(input);
      const mock = await getMock();
      console.log(url, config, mock);
      return mock;
    };
  }

  return async (input: Api[K]['input']) => {
    const { url, config } = prepareRequest(input);
    return await fetch(url, config).then(async (response) => {
      if (response.ok) {
        return response.json() as Promise<Api[K]['output']>;
      }
      throw new Error(await response.text());
    });
  };
}
