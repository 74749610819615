import '../WalletDrawer.scss';

import clsx from 'clsx';
import { ArrowDown2, Copy } from 'iconsax-react';
import { memo } from 'react';
import { SOLEXPLORER_BY_ADDRESS } from 'src/config';
import useBoolean from 'src/hooks/useBoolean';
import { WalletDrawerPortfolioBalanceProps } from 'src/types/widgets/WalletDrawer.types';

import Text from '../../Text/Text';

const WalletDrawerPortfolioBalance = memo<WalletDrawerPortfolioBalanceProps>((props) => {
  const { connectedWallet, balance } = props;
  const { copyTokenAddress } = connectedWallet;
  const { address, addressDisplay, token, denom } = balance;
  const [visible, setVisible] = useBoolean();
  return (
    token && (
      <div className="my-4 rounded-md bg-card p-3">
        <div className="flex items-center justify-between">
          <img src={token.logoURI} alt={token.name} className="size-7 rounded" />
          <div className="ml-4 flex-1">
            <Text variant="16-semibold" className="text-_B7B7B9">
              {token.name}
            </Text>
            <div className="mt-1 flex">
              <Text variant="12-medium" className="cursor-pointer text-_B7B7B9 underline">
                <a href={SOLEXPLORER_BY_ADDRESS(address)} target="_blank" rel="noreferrer">
                  {addressDisplay}
                </a>
              </Text>
              <Copy
                variant="Bold"
                size={16}
                className="ml-2 cursor-pointer text-_B7B7B9"
                onClick={() => copyTokenAddress(token)}
              />
            </div>
          </div>
          <Text variant="16-medium" className="self-start text-_B7B7B9">
            {'$15.484,48'}
          </Text>
          <ArrowDown2
            size={20}
            className={clsx(
              'ml-2 transform cursor-pointer self-start transition-transform duration-500',
              visible ? 'rotate-0' : 'rotate-180'
            )}
            onClick={setVisible.toggle}
          />
        </div>
        <div
          className={clsx(
            'overflow-hidden transition-[max-height] duration-500 ease-in-out',
            visible ? 'max-h-screen' : 'max-h-0'
          )}
        >
          <div className={clsx('transition-[margin] duration-500 ease-in-out', visible && 'mt-4')}>
            <div className="my-1 rounded-lg p-3 hover:bg-_13141D">
              <div className="flex items-center justify-between">
                <img src={token.logoURI} alt={token.name} className="size-5 rounded" />
                <div className="ml-4 flex-1">
                  <Text variant="16-medium" className="text-left text-_B7B7B9">
                    {token.symbol}
                  </Text>
                </div>
                <div>
                  <Text variant="16-medium" className="text-right text-_B7B7B9">
                    {denom}
                  </Text>
                  <Text variant="12-semibold" className="text-_9FA3C0">
                    {'$0,053733'}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
});

export default WalletDrawerPortfolioBalance;
