import { useMutation } from '@tanstack/react-query';
import { Api, ApiId } from 'src/types/networking/api.types';

import { hasKeys } from './basic.utils';

export function stringifyQueryParams<T extends Record<string, string | number | boolean>>(
  queryParams: T
) {
  return Object.fromEntries(
    Object.entries(queryParams).map(([key, value]) => [key, String(value)])
  );
}

export function injectQueryParams<T extends Record<string, string>>(queryParams: T) {
  return hasKeys(queryParams) ? `?${new URLSearchParams(queryParams).toString()}` : '';
}

export function injectPathParams<T extends Record<string, string>>(pathParams: T, url: string) {
  return url.replace(/\$\{(.*?)\}/g, (match, key) => {
    return key in pathParams ? pathParams[key] : match;
  });
}

export function useTypedMutation<K extends ApiId>(
  ...params: Parameters<typeof useMutation<Api[K]['output'], Error, Api[K]['input']>>
) {
  return useMutation<Api[K]['output'], Error, Api[K]['input']>(...params);
}
