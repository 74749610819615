import './BgBoxes.scss';

import clsx from 'clsx';
import { memo, useEffect, useRef } from 'react';
import { BgBoxesProps } from 'src/types/widgets/BgBoxes.types';

const BgBoxes = memo<BgBoxesProps>((props) => {
  const {
    className,
    isAnimated,
    width = 418,
    height = 500,
    fill = '#fff',
    opacity = 0.4,
    ...otherProps
  } = props;

  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (svgRef.current) {
      const paths = svgRef.current.querySelectorAll('path');
      paths.forEach((path) => {
        path.style.animationDelay = `${Math.random()}s`;
      });
    }
  }, []);

  return (
    <svg
      ref={svgRef}
      className={clsx(className, isAnimated && 'boxes-transition')}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 418 500"
      {...otherProps}
    >
      <g fill={fill} opacity={opacity}>
        <path
          fillOpacity={0.4}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 183.433 221.416)"
        />
        <path
          fillOpacity={0.6}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 230.163 269.944)"
        />
        <path
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 183.433 269.944)"
        />
        <path
          fillOpacity={0.8}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 136.702 172.889)"
        />
        <path
          fillOpacity={0.8}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 136.702 126.159)"
        />
        <path
          fillOpacity={0.8}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 214.402 0)"
        />
        <path
          fillOpacity={0.6}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 136.702 269.944)"
        />
        <path
          fillOpacity={0.4}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 89.972 269.944)"
        />
        <path
          fillOpacity={0.2}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 43.243 269.944)"
        />
        <path
          fillOpacity={0.2}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 43.243 221.416)"
        />
        <path
          fillOpacity={0.4}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 89.972 316.674)"
        />
        <path
          fillOpacity={0.2}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 43.243 316.674)"
        />
        <path
          fillOpacity={0.2}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 43.243 363.404)"
        />
        <path
          fillOpacity={0.6}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 136.702 316.674)"
        />
        <path
          fillOpacity={0.6}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 136.702 363.404)"
        />
        <path
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 183.433 316.674)"
        />
        <path
          fillOpacity={0.8}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 183.433 410.134)"
        />
        <path
          fillOpacity={0.6}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 230.163 316.674)"
        />
        <path
          fillOpacity={0.8}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 230.163 363.404)"
        />
        <path
          fillOpacity={0.8}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 230.163 410.134)"
        />
        <path
          fillOpacity={0.8}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 276.893 363.404)"
        />
        <path
          fillOpacity={0.8}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 323.623 363.404)"
        />
        <path
          fillOpacity={0.8}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 370.354 363.404)"
        />
        <path
          fillOpacity={0.8}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 417.083 363.404)"
        />
        <path
          fillOpacity={0.4}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 370.354 410.134)"
        />
        <path
          fillOpacity={0.2}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 370.354 456.865)"
        />
        <path
          fillOpacity={0.8}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 276.893 410.134)"
        />
        <path
          fillOpacity={0.8}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 276.893 456.865)"
        />
        <path
          fillOpacity={0.4}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 323.623 456.865)"
        />
        <path
          fillOpacity={0.8}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 276.893 316.674)"
        />
        <path
          fillOpacity={0.4}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 323.623 316.674)"
        />
        <path
          fillOpacity={0.6}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 276.893 269.944)"
        />
        <path
          fillOpacity={0.4}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 230.163 221.416)"
        />
        <path
          fillOpacity={0.2}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 276.893 221.416)"
        />
        <path
          fillOpacity={0.2}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 276.893 174.686)"
        />
        <path
          fillOpacity={0.2}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 276.893 127.956)"
        />
        <path
          fillOpacity={0.2}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 323.623 221.416)"
        />
        <path
          fillOpacity={0.2}
          d="M0 0h43.135v43.135H0z"
          style={{ mixBlendMode: 'overlay' }}
          transform="matrix(-1 0 0 1 323.623 174.686)"
        />
      </g>
    </svg>
  );
});

export default BgBoxes;
