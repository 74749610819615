import { getUserBalances } from '@rever22411/amm-commands';
import { useQuery } from '@tanstack/react-query';
import { safeArray } from 'src/utils/safety.utils';

import useWalletPublicKey from '../useWalletPublicKey';

export default function useGetUserBalances() {
  const { publicKeyString } = useWalletPublicKey();

  const query = useQuery({
    queryKey: ['userBalances', publicKeyString],
    queryFn: () => getUserBalances(publicKeyString),
    enabled: !!publicKeyString,
  });

  const userBalances = safeArray(query.data);

  const userBalancesRecord: Record<string, string> = userBalances.reduce(
    (acc, balance) => ({ ...acc, [balance.address]: balance.balance }),
    {}
  );

  return { ...query, userBalances, userBalancesRecord };
}
