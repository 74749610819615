import { Dialog } from '@headlessui/react';
import { memo } from 'react';
import { DrawerProps } from 'src/types/widgets/Drawer.types';

const Drawer = memo<DrawerProps>(({ visible, onClose, children }) => {
  return (
    <Dialog
      open={visible}
      onClose={onClose}
      transition
      className="fixed inset-0 z-10 translate-x-0 select-none bg-opacity-0 transition-all duration-300 ease-in-out data-[closed]:translate-x-full focus:outline-0"
    >
      <section className="absolute right-0 h-full w-[438px] max-w-full bg-white">
        {children}
      </section>
      <section className="h-full w-screen select-none" onClick={onClose} />
    </Dialog>
  );
});

export default Drawer;
