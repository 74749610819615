import clsx from 'clsx';
import { memo } from 'react';
import { ButtonProps } from 'src/types/widgets/Button.types';

import { ButtonVariants } from './ButtonVariants';

const Button = memo<ButtonProps>((props) => {
  const { variant = 'base', type = 'button', className, children, ...otherProps } = props;
  const { renderChildren = (c) => c, ...variantProps } = ButtonVariants[variant];
  return (
    <button
      type={type}
      {...variantProps}
      {...otherProps}
      className={clsx(variantProps.className, className)}
    >
      {renderChildren(children)}
    </button>
  );
});

export default Button;
