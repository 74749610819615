import { memo } from 'react';
import { AlertProps } from 'src/types/widgets/Alert.types';

import Text from '../../Text/Text';
import AlertError from '../templates/AlertError';
import AlertPending from '../templates/AlertPending';
import AlertSuccess from '../templates/AlertSuccess';

const AlertBody = memo<AlertProps>((props) => {
  const { overtitle, title, description, pending, success, error, children } = props;

  if (pending) return <AlertPending {...props} />;
  if (success) return <AlertSuccess {...props} />;
  if (error) return <AlertError {...props} />;

  return (
    children || (
      <>
        <Text variant="16-light" className="text-white/80">
          {overtitle}
        </Text>
        <Text variant="24-medium" className="mt-2 text-white/80">
          {title}
        </Text>
        <Text variant="16-light" className="text-white/80">
          {description}
        </Text>
      </>
    )
  );
});

export default AlertBody;
