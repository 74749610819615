import './i18n';

import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import JwtRoot from 'src/roots/JwtRoot';
import NetworkingRoot from 'src/roots/NetworkingRoot';
import SolanaRoot from 'src/roots/SolanaRoot';
import StartupRoot from 'src/roots/StartupRoot';
import Footer from 'src/ui/widgets/Footer/Footer';
import Header from 'src/ui/widgets/Header/Header';
import MobileNavigationBar from 'src/ui/widgets/MobileNavigationBar/MobileNavigationBar';
import Trending from 'src/ui/widgets/Trending/Trending';

import AlertsRoot from './roots/AlertsRoot';

const Swap = lazy(() => import('src/ui/pages/Swap/Swap'));
const Market = lazy(() => import('src/ui/pages/Market/Market'));
const MarketDetail = lazy(() => import('src/ui/pages/MarketDetail/MarketDetail'));
const Creation = lazy(() => import('src/ui/pages/Creation/Creation'));
const SolPump = lazy(() => import('src/ui/pages/SolPump/SolPump'));
const CurveDetail = lazy(() => import('src/ui/pages/CurveDetail/CurveDetail'));
const Showcases = lazy(() => import('src/ui/pages/Showcases/Showcases'));

const App = () => {
  return (
    <BrowserRouter>
      <NetworkingRoot>
        <StartupRoot />
        <SolanaRoot>
          <JwtRoot />
          <AlertsRoot />
          <div>
            {/* TODO Header fixed on scroll */}
            <Trending />
            <Header />
          </div>
          <main className="container mx-auto my-6 min-h-[calc(100vh-116px-50px-50px-64px)] px-4">
            <Suspense fallback={'loading...'}>
              {/* <Alert className='w-full max-w-full' variant='error'>
                <Text variant='16-normal' className='flex items-center gap-1'>
                  <Danger variant='Bulk' size={18} />
                  The platform is in beta-testing! Use it at your own risk, we are NOT responsable for any loss of assets caused by errors!
                </Text>
              </Alert> */}
              <Routes>
                <Route path="/swap" element={<Swap />} />
                <Route path="/market" element={<Market />} />
                <Route path="/market/detail" element={<MarketDetail />} />
                <Route path="/curves" element={<SolPump />} />
                <Route path="/curves/detail" element={<CurveDetail />} />
                <Route path="/showcases" element={<Showcases />} />
                <Route path="/*" element={<Navigate to="/swap " replace />} />
              </Routes>
            </Suspense>
          </main>
          <Creation />
          <Footer />
          <MobileNavigationBar />
        </SolanaRoot>
      </NetworkingRoot>
    </BrowserRouter>
  );
};

export default App;
