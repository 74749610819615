import '../Header.scss';

import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { HeaderLinkProps } from 'src/types/widgets/HeaderLink.types';

import Text from '../../Text/Text';

const HeaderLink = memo(({ to, label }: HeaderLinkProps) => {
  return (
    <Text variant="18-normal">
      <NavLink
        to={to}
        className="[aria-current]:text-_839EE7 [aria-current]:pointer-events-none rounded bg-transparent px-4 py-2 text-white/50 hover:bg-white/5"
      >
        <span className="text-gradient">{label}</span>
      </NavLink>
    </Text>
  );
});

export default HeaderLink;
