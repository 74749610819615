import { CloseCircle, CloudRemove, Copy } from 'iconsax-react';
import { memo } from 'react';
import { AlertProps } from 'src/types/widgets/Alert.types';
import { copy } from 'src/utils/utils';

import FormattedNumber from '../../FormattedNumber/FormattedNumber';
import Text from '../../Text/Text';

const AlertError = memo<AlertProps>(({ error }) => {
  return (
    error && (
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-3">
          <CloudRemove size={18} className="text-_FFD1C0" variant="Linear" />
          <Text variant="14-semibold" className="text-_FFD1C0CC">
            {'Something went wrong!'}
          </Text>
        </div>
        <div className="flex gap-3 border-2 border-transparent border-l-_FFD1C0CC bg-white/10 px-4 py-3">
          <Text variant="12-medium" className="line-clamp-6">
            {error.message}
          </Text>
          <Copy
            size={18}
            className="z-10 min-w-[18px] cursor-pointer text-_FFD1C0CC"
            variant="Bulk"
            onClick={() => copy(error.message)}
          />
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <Text variant="14-medium" className="text-_FFD1C0CC">
              <FormattedNumber
                value={error.amountA}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            </Text>
            <img src={error.logoUriA} className="size-6 rounded-full" />
          </div>
          <CloseCircle size={18} className="text-_FFD1C0" variant="Linear" />
          <div className="flex items-center gap-2">
            <img src={error.logoUriB} className="size-6 rounded-full" />
            <Text variant="14-medium" className="text-_FFD1C0CC">
              <FormattedNumber
                value={error.amountB}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            </Text>
          </div>
        </div>
      </div>
    )
  );
});

export default AlertError;
