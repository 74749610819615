import { useAtom } from 'jotai';
import { atomAlerts } from 'src/storage/atoms';
import {
  AlertErrorProps,
  AlertPendingProps,
  AlertProps,
  AlertSuccessProps,
} from 'src/types/widgets/Alert.types';

export default function useAlerts() {
  const [alerts, setAlerts] = useAtom(atomAlerts);

  const removeAlert = (timestamp: number) => {
    setAlerts((currentAlerts) => currentAlerts.filter((a) => a.timestamp !== timestamp));
  };

  const addAlert = (alert: AlertProps) => {
    const timestamp = Date.now();
    setAlerts((currentAlerts) => [
      ...currentAlerts.filter((a) => alert.persistent || !a.persistent),
      { ...alert, timestamp },
    ]);
    if (!alert.persistent) {
      setTimeout(() => {
        setAlerts((currentAlerts) => currentAlerts.filter((a) => a.timestamp !== timestamp));
      }, 7500);
    }
  };

  const addAlertPending = (description = 'Your request is being processed...') => {
    addAlert({
      variant: 'info',
      title: 'Request pending...',
      description,
      animated: true,
      persistent: true,
    });
  };

  const addAlertError = (description = 'An error has occurred') => {
    addAlert({ variant: 'error', title: 'Request failed!', description });
  };

  const addAlertSuccess = (description = 'Your request has been processed successfully.') => {
    addAlert({ variant: 'success', title: 'Request succeeded!', description });
  };

  const addAlertPendingTemplate = (pending: AlertPendingProps) => {
    addAlert({ variant: 'info', animated: true, persistent: true, pending });
  };

  const addAlertSuccessTemplate = (success: AlertSuccessProps) => {
    addAlert({ variant: 'success', success });
  };

  const addAlertErrorTemplate = (error: AlertErrorProps) => {
    addAlert({ variant: 'error', error });
  };

  return {
    alerts,
    removeAlert,
    addAlert,
    addAlertPending,
    addAlertError,
    addAlertSuccess,
    addAlertPendingTemplate,
    addAlertSuccessTemplate,
    addAlertErrorTemplate,
  };
}
