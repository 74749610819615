import clsx from 'clsx';
import { memo } from 'react';
import { SocialListProps } from 'src/types/widgets/SocialList.types';

const SocialList = memo<SocialListProps>(({ className }) => {
  return (
    <div className={clsx('flex gap-4 opacity-75', className)}>
      <a>
        <img src="/images/social/telegram.svg" alt="telegram-logo" className="size-6" />
      </a>
      <a>
        <img src="/images/social/medium.svg" alt="medium-logo" className="size-6" />
      </a>
      <a>
        <img src="/images/social/twitter.svg" alt="twitter-logo" className="size-6" />
      </a>
    </div>
  );
});

export default SocialList;
