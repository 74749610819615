import '../WalletDrawer.scss';

import clsx from 'clsx';
import { memo } from 'react';
import { WalletDrawerHeaderTabProps } from 'src/types/widgets/WalletDrawer.types';

import Text from '../../Text/Text';

const WalletDrawerHeaderTab = memo<WalletDrawerHeaderTabProps>((props) => {
  const tab = props.tabs[props.tabName];
  const active = props.activeTab === props.tabName;
  return (
    <div
      className={clsx(
        'flex flex-1 cursor-pointer flex-row items-center justify-center border-b pb-4 pt-2',
        active ? 'border-primary' : 'border-transparent'
      )}
      onClick={() => props.setActiveTab(props.tabName)}
    >
      {tab[active ? 'iconActive' : 'iconInactive']}
      <Text variant="16-medium" className={clsx('ml-2', active ? 'text-primary' : 'text-_8E8B90')}>
        {tab.label}
      </Text>
    </div>
  );
});

export default WalletDrawerHeaderTab;
