import { Notification, Wallet1 } from 'iconsax-react';
import { useAtom } from 'jotai';
import { useState } from 'react';
import useBoolean from 'src/hooks/useBoolean';
import { atomWalletDrawerBannerShowed } from 'src/storage/atoms';
import {
  WalletDrawerAlert,
  WalletDrawerTab,
  WalletDrawerTabName,
} from 'src/types/widgets/WalletDrawer.types';

const NUMBER_OF_VISIBLE_ALERTS = 4;

export const todo = () => alert('TODO');

export default function useWalletDrawer() {
  const alerts: WalletDrawerAlert[] = [
    {
      date: 'Friday 11, October 2024',
      title: 'Short notification title!',
      description: 'A bit of description here but not too much!',
      new: true,
    },
    {
      date: 'Friday 11, October 2024',
      title: 'Short notification title!',
      description: 'A bit of description here but not too much!',
    },
    {
      date: 'Friday 11, October 2024',
      title: 'Short notification title!',
      description: 'A bit of description here but not too much!',
      onAccept: todo,
      onRefuse: todo,
    },
    {
      date: 'Friday 11, October 2024',
      title: 'Short notification title!',
      description: 'A bit of description here but not too much!',
      onReadMore: todo,
    },
    {
      date: 'Friday 11, October 2024',
      title: 'Short notification title!',
      description: 'A bit of description here but not too much!',
      new: true,
    },
    {
      date: 'Friday 11, October 2024',
      title: 'Short notification title!',
      description: 'A bit of description here but not too much!',
      onAccept: todo,
      onRefuse: todo,
    },
  ];

  const tabs: Record<WalletDrawerTabName, WalletDrawerTab> = {
    portfolio: {
      label: 'Portfolio',
      iconActive: <Wallet1 variant="Bulk" size={20} className="text-primary" />,
      iconInactive: <Wallet1 variant="Bulk" size={20} className="text-_8E8B90" />,
      iconAlt: 'portfolio',
    },
    notification: {
      label: `Alerts (${alerts.length})`,
      iconActive: <Notification variant="Bulk" size={20} className="text-primary" />,
      iconInactive: <Notification variant="Bulk" size={20} className="text-_8E8B90" />,
      iconAlt: 'notification',
    },
  };

  const [activeTab, setActiveTab] = useState<WalletDrawerTabName>('portfolio');
  const [someHiddenAlerts, setSomeHiddenAlerts] = useBoolean(true);

  const visibleAlerts = someHiddenAlerts ? alerts.slice(0, NUMBER_OF_VISIBLE_ALERTS) : alerts;

  // TODO: gestire persistenza
  const [bannerShowed, setBannerShowed] = useAtom(atomWalletDrawerBannerShowed);
  const hideBanner = () => {
    setBannerShowed(true);
  };

  return {
    tabs,
    activeTab,
    setActiveTab,
    someHiddenAlerts,
    setSomeHiddenAlerts,
    visibleAlerts,
    bannerShowed,
    hideBanner,
  };
}
