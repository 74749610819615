import './Header.scss';

import { Input } from '@headlessui/react';
import { SearchNormal, Wallet1 } from 'iconsax-react';
import { memo } from 'react';
import Logo from 'src/ui/widgets/Logo/Logo';
import WalletDrawer from 'src/ui/widgets/WalletDrawer/WalletDrawer';

import ButtonWallet from '../ButtonWallet/ButtonWallet';
import Text from '../Text/Text';
import HeaderLink from './components/HeaderLink';
import useHeader from './useHeader';

const Header = memo(() => {
  const lo = useHeader();

  return (
    <>
      <header className="relative z-10">
        <div className="container mx-auto my-6 flex animate-fade-down flex-row flex-wrap items-center justify-between px-4">
          <div className="inner flex w-full items-center gap-2">
            <Logo className="animate-fade animate-delay-75" />

            <nav className="mx-5 hidden animate-fade flex-row gap-4 animate-delay-150 md:flex">
              <HeaderLink to="/swap" label="Swap" />
              <HeaderLink to="/market" label="Market" />
              <HeaderLink to="/curves" label="SolPump" />
              <HeaderLink to="/showcases" label="Showcases" />
            </nav>
            <div className="grow" />
            <div className="flex items-center gap-8">
              <Text
                variant="14-normal"
                className="hidden select-none items-center gap-2 rounded-sm border border-solid border-white/15 bg-white/5 px-4 py-2 focus-within:ring-2 lg:flex"
              >
                <SearchNormal size={16} className="text-white/40" />

                <Input
                  placeholder="Search..."
                  className="w-24 border-none bg-transparent p-0 focus:outline-none"
                />
              </Text>

              <ButtonWallet onClick={lo.setVisibleWalletDrawer.on}>
                {lo.connectedWallet.wallet ? (
                  <>
                    <Wallet1 size={16} variant="Bold" />
                    {lo.connectedWallet.walletAddress}
                  </>
                ) : (
                  'Connect Wallet'
                )}
              </ButtonWallet>
            </div>
          </div>
        </div>
      </header>
      <WalletDrawer
        visible={lo.visibleWalletDrawer}
        onClose={lo.setVisibleWalletDrawer.off}
        connectedWallet={lo.connectedWallet}
      />
    </>
  );
});

export default Header;
