import './Header.scss';

import useBoolean from 'src/hooks/useBoolean';
import useConnectedWallet from 'src/hooks/useConnectedWallet';

export default function useHeader() {
  const connectedWallet = useConnectedWallet();

  const [visibleWalletDrawer, setVisibleWalletDrawer] = useBoolean();

  return { connectedWallet, visibleWalletDrawer, setVisibleWalletDrawer };
}
