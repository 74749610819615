import { ApiId } from 'src/types/networking/api.types';
import { MocksConfig } from 'src/types/networking/mock.types';

import getBondCurveCandles from './json/getBondCurveCandles.json';
import getBondCurves from './json/getBondCurves.json';
import getBondSwapEvents from './json/getBondSwapEvents.json';
import getMe from './json/getMe.json';
import getPairCandles from './json/getPairCandles.json';
import getPairs from './json/getPairs.json';
import getPairTransactions from './json/getPairTransactions.json';
import getPairVolumes from './json/getPairVolumes.json';
import getStatistics from './json/getStatistics.json';
import getStreamings from './json/getStreamings.json';
import getTokens from './json/getTokens.json';

export default function useMockConfig(apiId: ApiId) {
  const mocksConfig: MocksConfig = {
    getMe: { mocks: { 200: getMe } },
    getTokens: { mocks: { 200: getTokens } },
    getPairs: { mocks: { 200: getPairs } },
    getPairCandles: { mocks: { 200: getPairCandles } },
    getPairTransactions: { mocks: { 200: getPairTransactions } },
    getPairVolumes: { mocks: { 200: getPairVolumes } },
    getBondCurves: { mocks: { 200: getBondCurves } },
    getBondCurveCandles: { mocks: { 200: getBondCurveCandles } },
    getBondSwapEvents: { mocks: { 200: getBondSwapEvents } },
    postToken: { mocks: { 200: {} } },
    postPair: { mocks: { 200: {} } },
    getStatistics: { mocks: { 200: getStatistics } },
    getStreamings: { mocks: { 200: getStreamings } },
    postStartStreaming: { mocks: { 200: getStreamings } },
    postStopStreaming: { mocks: { 200: {} } },
  };
  return mocksConfig[apiId];
}
