import '../WalletDrawer.scss';

import { memo } from 'react';
import { WalletDrawerNotificationProps } from 'src/types/widgets/WalletDrawer.types';
import Button from 'src/ui/widgets/Button/Button';

import Text from '../../Text/Text';
import WalletDrawerNotificationAlert from './WalletDrawerNotificationAlert';

const WalletDrawerNotification = memo<WalletDrawerNotificationProps>((props) => {
  return (
    <div className="mt-8">
      {props.visibleAlerts.length === 0 ? (
        <div className="px-[61px] py-[120px]">
          <img src="/images/walletDrawer/zeroAlerts.svg" alt="zero alerts" className="mx-auto" />
          <Text variant="16-semibold" className="mt-5 text-center text-_73747A">
            {'Currently, nothing to report!'}
          </Text>
          <Text variant="16-normal" className="mt-2 text-center text-_73747A">
            {
              'This area will light up with new notifications once there is activity in your wallet!'
            }
          </Text>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between">
            <Text variant="16-semibold" className="text-_73747A">
              {'Latest'}
            </Text>
          </div>
          {props.visibleAlerts.map((alert, i) => (
            <WalletDrawerNotificationAlert key={i} alert={alert} />
          ))}
          <div className="mt-4 flex justify-center">
            <Button onClick={props.setSomeHiddenAlerts.toggle} className="p-0 ">
              <Text variant="12-normal" className="text-center text-primary">
                {props.someHiddenAlerts ? 'Show hidden notifications' : 'Hide some notifications'}
              </Text>
            </Button>
          </div>
        </>
      )}
    </div>
  );
});

export default WalletDrawerNotification;
