import { ArrowSwapHorizontal, Clock, CloudChange } from 'iconsax-react';
import { memo } from 'react';
import { AlertProps } from 'src/types/widgets/Alert.types';

import FormattedNumber from '../../FormattedNumber/FormattedNumber';
import Text from '../../Text/Text';

const AlertPending = memo<AlertProps>(({ pending }) => {
  return (
    pending && (
      <div className="flex items-center gap-4">
        <Clock size={54} className="text-primary-light" variant="Bulk" />
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-3">
            <CloudChange size={18} className="text-primary-light" variant="Linear" />
            <Text variant="14-semibold" className="text-primary-light">
              {'Waiting for user approval!'}
            </Text>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <Text variant="14-medium" className="text-primary-light">
                <FormattedNumber
                  value={pending.amountA}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </Text>
              <img src={pending.logoUriA} className="size-6 rounded-full" />
            </div>
            <ArrowSwapHorizontal
              size={18}
              className="-scale-x-100 text-primary-light"
              variant="TwoTone"
            />
            <div className="flex items-center gap-2">
              <img src={pending.logoUriB} className="size-6 rounded-full" />
              <Text variant="14-medium" className="text-primary-light">
                <FormattedNumber
                  value={pending.amountB}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </Text>
            </div>
          </div>
        </div>
      </div>
    )
  );
});

export default AlertPending;
