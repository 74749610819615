import { useInfiniteQuery } from '@tanstack/react-query';
import { LIMIT_BOND_CURVES } from 'src/config';
import useApi from 'src/networking/api/useApi';
import { safeArray, safeString } from 'src/utils/safety.utils';

import useGetTokens from './useGetTokens';

export default function useGetBondCurves(tokenMint = '') {
  const getBondCurves = useApi('getBondCurves');

  const query = useInfiniteQuery({
    queryKey: ['bondCurves'],
    queryFn: ({ pageParam }) =>
      getBondCurves({ queryParams: { page: pageParam, limit: LIMIT_BOND_CURVES } }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, _, lastPageParam) =>
      safeArray(lastPage.data).length === 0 ? undefined : lastPageParam + 1,
  });

  const curves = safeArray(query.data?.pages.flatMap(({ data }) => data));

  const curveAddress = safeString(new URLSearchParams(window.location.search).get('address'));
  const curve = curves.find(({ address }) => address === curveAddress);

  const { tokens } = useGetTokens();
  const token = tokens.find(({ address }) => address === (tokenMint || curve?.tokenMint));

  return { ...query, curves, curveAddress, curve, token };
}
