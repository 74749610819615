export function formatPositiveInteger(value: number) {
  let result = Math.trunc(value);
  if (result <= 0) result = 0; //il trunc potrebbe restituire -0
  return Intl.NumberFormat(navigator.languages, {
    minimumIntegerDigits: 2,
  }).format(result);
}

export function formatBalance(value: number, isMobile = false) {
  if (value === null || value === undefined || isNaN(value)) return '-';
  if (value === 0) return '0';

  const intl = new Intl.NumberFormat(navigator.languages, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  });

  if (isMobile) {
    // Handle billions by putting a B at the end
    if (value > 1000000000) {
      return `${intl.format(Number((value / 10 ** 9).toFixed(2)))} B`;
    }
    // Handle millions by putting an M at the end
    if (value > 1000000) {
      return `${intl.format(Number((value / 10 ** 6).toFixed(2)))} M`;
    }
    // Handle thousands by putting an M at the end
    if (value > 1000) {
      return `${intl.format(Number((value / 1000).toFixed(2)))} K`;
    }
    if (value < 1) {
      const intled = intl.format(value);
      const zeros = intled.substring(2).match(/0+/)?.[0];
      if (zeros && zeros.length > 2) {
        const [pre, post] = intled.split(zeros);
        return (
          <>
            {pre}0<sub className="self-end text-[70%]">{zeros.length}</sub>
            {post}
          </>
        );
      }
      return intled;
    }
  }
  // Handle the rest of the numbers by falling back to the default behavior
  return intl.format(Number(value));
}

export function copy(value?: string) {
  navigator.clipboard
    .writeText(value ?? '')
    .then(() => {
      alert('Text copyed!');
    })
    .catch((err) => {
      console.error('Error while copyng the value: ', err);
    });
}
