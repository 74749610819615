import { CorrectPair } from 'src/types/data.types';

import { safeNumber } from './safety.utils';

export function getTypedKeys<T extends object>(obj: T) {
  return Object.keys(obj) as (keyof T)[];
}

export function getTypedEntries<T extends object>(obj: T) {
  return Object.entries(obj) as [keyof T, T[keyof T]][];
}

export function stringifyRecord<T extends Record<string, string | number | boolean>>(obj: T) {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, String(value)]));
}

export function isValorized<V>(value: V): value is Exclude<V, null | undefined> {
  return value !== null && value !== undefined;
}

export function isString<V>(value: V) {
  return typeof value === 'string' || value instanceof String;
}

export function hasKeys<V extends object>(value: V) {
  return Object.keys(value).length > 0;
}

export function deepCopy<V>(value: V) {
  return JSON.parse(JSON.stringify(value)) as V;
}

export function deepEquals<V>(value1: V, value2: V) {
  return JSON.stringify(value1) === JSON.stringify(value2);
}

export function deleteKey<T extends Record<string, unknown>>(record: T, key = '') {
  const recordCopy = deepCopy(record);
  delete recordCopy[key];
  return recordCopy;
}

export function deleteNullValues<T extends Record<string, unknown>>(record: T) {
  const recordCopy = deepCopy(record);
  Object.keys(record)
    .filter((key) => recordCopy[key] === null)
    .forEach((key) => delete recordCopy[key]);
  return recordCopy;
}

export function removeDuplicates<T>(arr: T[], idProp: keyof T) {
  return arr.filter((x, i) => arr.findIndex((y) => x[idProp] === y[idProp]) === i);
}

export function sleep(milliseconds: number) {
  return new Promise((res) => setTimeout(res, milliseconds));
}

export function computeStartPrice(pool: CorrectPair, activeRiskLevel: string) {
  const currentPriceRatio = safeNumber(pool.currentPriceRatio);
  return currentPriceRatio - (currentPriceRatio * +activeRiskLevel) / 100;
}

export function computeEndPrice(pool: CorrectPair, activeRiskLevel: string) {
  const currentPriceRatio = safeNumber(pool.currentPriceRatio);
  return currentPriceRatio + (currentPriceRatio * +activeRiskLevel) / 100;
}
