import { useQueryClient } from '@tanstack/react-query';

export default function useInvalidateQueries() {
  const queryClient = useQueryClient();

  const invalidatePairs = () => {
    queryClient.invalidateQueries({ queryKey: ['pairs'] });
  };

  const invalidateTokens = () => {
    queryClient.invalidateQueries({ queryKey: ['tokens'] });
  };

  const invalidateAll = () => {
    queryClient.invalidateQueries({ queryKey: ['userBalances'] });
    queryClient.invalidateQueries({ queryKey: ['tokenBalance'] });
    invalidatePairs();
    invalidateTokens();
  };

  return { invalidatePairs, invalidateTokens, invalidateAll };
}
