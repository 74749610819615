import './WalletDrawer.scss';

import { Add, ProfileCircle } from 'iconsax-react';
import { memo } from 'react';
import { WalletDrawerProps } from 'src/types/widgets/WalletDrawer.types';

import Drawer from '../Drawer/Drawer';
import WalletDrawerHeaderTab from './components/WalletDrawerHeaderTab';
import WalletDrawerNotification from './components/WalletDrawerNotification';
import WalletDrawerPortfolio from './components/WalletDrawerPortfolio';
import useWalletDrawer from './useWalletDrawer';

const WalletDrawer = memo<WalletDrawerProps>((props) => {
  const lo = useWalletDrawer();
  return (
    <Drawer {...props}>
      <div className="h-full overflow-y-scroll">
        <div className="neon-border neon-border-shadow absolute left-0 top-0 size-full" />
        <div className="neon-border pl-1">
          <div className="h-full min-h-screen bg-_161823 p-6">
            <div className="bg-_15161F">
              <div className="flex flex-row items-center">
                <ProfileCircle size={24} className="text-white/40" variant="Bold" />
                <p className="ml-2 flex-1 text-white opacity-40">{'My Profile'}</p>
                <Add
                  size={30}
                  onClick={props.onClose}
                  className="rotate-45 cursor-pointer text-_717375"
                />
              </div>
              <div className="mt-6 flex flex-row items-center">
                <WalletDrawerHeaderTab {...lo} tabName="portfolio" />
                <WalletDrawerHeaderTab {...lo} tabName="notification" />
              </div>
            </div>
            {lo.activeTab === 'portfolio' ? (
              <WalletDrawerPortfolio {...lo} connectedWallet={props.connectedWallet} />
            ) : (
              <WalletDrawerNotification {...lo} />
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
});

export default WalletDrawer;
