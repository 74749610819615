import { AnchorProvider, setProvider as setAnchorProvider } from '@coral-xyz/anchor';
import { sha256 } from '@noble/hashes/sha256';
import * as secp from '@noble/secp256k1';
import { configDev, connection, setConnection } from '@rever22411/amm-commands';
import { useAnchorWallet, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { atomJwt, atomProvider, atomPublicKey } from 'src/storage/atoms';

export default function JwtRoot() {
  const { wallet } = useWallet();
  const anchorWallet = useAnchorWallet();

  const setJwt = useSetAtom(atomJwt);
  const setProvider = useSetAtom(atomProvider);
  const setPublicKey = useSetAtom(atomPublicKey);

  const updateJwt = async () => {
    const address = wallet?.adapter.publicKey?.toBase58();

    if (wallet && anchorWallet && address) {
      const privateKey = secp.etc.hashToPrivateKey(Buffer.from(address, 'utf-8'));
      const publicKey = Buffer.from(secp.getPublicKey(privateKey)).toString('base64');

      const header = JSON.stringify({ alg: 'ES256K', typ: 'JWT' });

      const message = JSON.stringify({
        expiry: Math.floor(Date.now() / 1000) + 43200,
        publicKey,
        address,
      });

      const hash = sha256(Buffer.from(message, 'utf-8'));
      const signature = (await secp.signAsync(hash, privateKey)).toCompactHex();
      const headerBase64 = Buffer.from(header).toString('base64');
      const messageBase64 = Buffer.from(message).toString('base64');

      const provider = new AnchorProvider(connection, anchorWallet, {
        preflightCommitment: 'processed',
      });
      setAnchorProvider(provider);

      // create JWT
      setJwt(`${headerBase64}.${messageBase64}.${signature}`);
      setProvider(provider);
      setPublicKey(new PublicKey(address));
    } else {
      setJwt('');
      setProvider(undefined);
      setPublicKey(undefined);
    }
  };

  useEffect(() => {
    updateJwt();
    if (anchorWallet) {
      setConnection(anchorWallet, configDev);
    }
  }, [anchorWallet, wallet]);

  return <></>;
}
