import clsx from 'clsx';
import { memo, useMemo } from 'react';
import useResponsive from 'src/hooks/useResponsive';
import { Breakpoint } from 'src/types/commons.types';
import { TextProps, TextVariant } from 'src/types/widgets/Text.types';

import { TextVariantClasses } from './TextVariantClasses';

const Text = memo<TextProps>(({ variant, Component = 'div', className, style, children }) => {
  const { upperThan } = useResponsive();

  const responsiveVariant = useMemo(() => {
    if (typeof variant === 'object') {
      const breakpoints: Breakpoint[] = ['2xl', 'xl', 'lg', 'md', 'sm', 'xs'];
      for (const breakpoint of breakpoints) {
        if (upperThan[breakpoint] && variant[breakpoint]) {
          return variant[breakpoint];
        }
      }
    }
    return variant as TextVariant;
  }, [variant, upperThan]);

  return (
    <Component className={clsx(TextVariantClasses[responsiveVariant], className)} style={style}>
      {children}
    </Component>
  );
});

export default Text;
