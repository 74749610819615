import { memo } from 'react';
import SocialList from 'src/ui/widgets/SocialList/SocialList';

import Text from '../Text/Text';

// TODO: aggiungere effective links

const Footer = memo(() => {
  return (
    <footer className="container mx-auto justify-between px-4 py-6 xl:flex">
      <div>
        <Text variant="18-normal" className="mb-5">
          {'Useful links'}
        </Text>
        <div className="flex gap-4 opacity-75">
          <a>{'Privacy Policy'}</a>
          <a>{'Risk advice'}</a>
          {/* <div className="flex gap-1">
            {'Powered by TerraCVita'}
            <img
              src="/images/terraVita.svg"
              alt="terravita-logo"
              height="18"
              width="18"
            />
          </div> */}
        </div>
      </div>
      <div>
        <div className="my-5 xl:mt-0 xl:text-right">{'Socials'}</div>
        <SocialList />
      </div>
    </footer>
  );
});

export default Footer;
