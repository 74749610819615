import { PublicKey } from '@solana/web3.js';

export function safeString(str: string | null | undefined, fallbackValue = '') {
  return str ?? fallbackValue;
}

export function safeNumber(num: number | string | null | undefined, fallbackValue = 0) {
  return isNaN(Number(num)) ? fallbackValue : Number(num);
}

export function safeArray<T>(arr: T[] | null | undefined, fallbackValue = []) {
  return arr ?? fallbackValue;
}

export function safeObject<T>(obj: T | null | undefined, fallbackValue = {}) {
  return obj ?? fallbackValue;
}

export function safePublicKey(token = '') {
  try {
    return new PublicKey(token);
  } catch {
    return undefined;
  }
}
