import { MOCKS_ALL_DISABLED, MOCKS_ALL_ENABLED } from 'src/config';
import { ApiId } from 'src/types/networking/api.types';
import { MockError } from 'src/types/networking/mock.types';
import { sleep } from 'src/utils/basic.utils';

import useMockConfig from './useMockConfig';

export default function useMock<T>(apiId: ApiId) {
  const mockConfig = useMockConfig(apiId);

  const isMocked = () => {
    const mockedWhen = {
      ['ALL_DISABLED=true, ALL_ENABLED=true']: false,
      ['ALL_DISABLED=true, ALL_ENABLED=false']: false,
      ['ALL_DISABLED=false, ALL_ENABLED=true']: true,
      ['ALL_DISABLED=false, ALL_ENABLED=false']: !mockConfig.disabled,
    };
    return mockedWhen[`ALL_DISABLED=${MOCKS_ALL_DISABLED}, ALL_ENABLED=${MOCKS_ALL_ENABLED}`];
  };

  const getMock = async () => {
    const { status = 200, mocks } = mockConfig;
    await sleep(1000);
    const data = mocks[status];
    if (data && status >= 200 && status <= 299) {
      return data as T;
    }
    throw new MockError(apiId, status, data);
  };

  return { isMocked, getMock };
}
