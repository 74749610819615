import { TextVariant } from 'src/types/widgets/Text.types';

export const TextVariantClasses: Record<TextVariant, string> = {
  '10-thin': 'font-sans text-[10px] font-thin leading-[12.1px]',
  '10-light': 'font-sans text-[10px] font-light leading-[12.1px]',
  '10-normal': 'font-sans text-[10px] font-normal leading-[12.1px]',
  '10-medium': 'font-sans text-[10px] font-medium leading-[12.1px]',
  '10-semibold': 'font-sans text-[10px] font-semibold leading-[12.1px]',
  '10-bold': 'font-sans text-[10px] font-bold leading-[12.1px]',
  '10-extrabold': 'font-sans text-[10px] font-extrabold leading-[12.1px]',

  '12-thin': 'font-sans text-[12px] font-thin leading-[14.52px]',
  '12-light': 'font-sans text-[12px] font-light leading-[14.52px]',
  '12-normal': 'font-sans text-[12px] font-normal leading-[14.52px]',
  '12-medium': 'font-sans text-[12px] font-medium leading-[14.52px]',
  '12-semibold': 'font-sans text-[12px] font-semibold leading-[14.52px]',
  '12-bold': 'font-sans text-[12px] font-bold leading-[14.52px]',
  '12-extrabold': 'font-sans text-[12px] font-extrabold leading-[14.52px]',

  '14-thin': 'font-sans text-[14px] font-thin leading-[16.94px]',
  '14-light': 'font-sans text-[14px] font-light leading-[16.94px]',
  '14-normal': 'font-sans text-[14px] font-normal leading-[16.94px]',
  '14-medium': 'font-sans text-[14px] font-medium leading-[16.94px]',
  '14-semibold': 'font-sans text-[14px] font-semibold leading-[16.94px]',
  '14-bold': 'font-sans text-[14px] font-bold leading-[16.94px]',
  '14-extrabold': 'font-sans text-[14px] font-extrabold leading-[16.94px]',

  '16-thin': 'font-sans text-[16px] font-thin leading-[19.36px]',
  '16-light': 'font-sans text-[16px] font-light leading-[19.36px]',
  '16-normal': 'font-sans text-[16px] font-normal leading-[19.36px]',
  '16-medium': 'font-sans text-[16px] font-medium leading-[19.36px]',
  '16-semibold': 'font-sans text-[16px] font-semibold leading-[19.36px]',
  '16-bold': 'font-sans text-[16px] font-bold leading-[19.36px]',
  '16-extrabold': 'font-sans text-[16px] font-extrabold leading-[19.36px]',

  '18-thin': 'font-sans text-[18px] font-thin leading-[21.78px]',
  '18-light': 'font-sans text-[18px] font-light leading-[21.78px]',
  '18-normal': 'font-sans text-[18px] font-normal leading-[21.78px]',
  '18-medium': 'font-sans text-[18px] font-medium leading-[21.78px]',
  '18-semibold': 'font-sans text-[18px] font-semibold leading-[21.78px]',
  '18-bold': 'font-sans text-[18px] font-bold leading-[21.78px]',
  '18-extrabold': 'font-sans text-[18px] font-extrabold leading-[21.78px]',

  '20-thin': 'font-sans text-[20px] font-thin leading-[24.2px]',
  '20-light': 'font-sans text-[20px] font-light leading-[24.2px]',
  '20-normal': 'font-sans text-[20px] font-normal leading-[24.2px]',
  '20-medium': 'font-sans text-[20px] font-medium leading-[24.2px]',
  '20-semibold': 'font-sans text-[20px] font-semibold leading-[24.2px]',
  '20-bold': 'font-sans text-[20px] font-bold leading-[24.2px]',
  '20-extrabold': 'font-sans text-[20px] font-extrabold leading-[24.2px]',

  '24-thin': 'font-sans text-[24px] font-thin leading-[29.05px]',
  '24-light': 'font-sans text-[24px] font-light leading-[29.05px]',
  '24-normal': 'font-sans text-[24px] font-normal leading-[29.05px]',
  '24-medium': 'font-sans text-[24px] font-medium leading-[29.05px]',
  '24-semibold': 'font-sans text-[24px] font-semibold leading-[29.05px]',
  '24-bold': 'font-sans text-[24px] font-bold leading-[29.05px]',
  '24-extrabold': 'font-sans text-[24px] font-extrabold leading-[29.05px]',

  '28-thin': 'font-sans text-[28px] font-thin leading-[33.89px]',
  '28-light': 'font-sans text-[28px] font-light leading-[33.89px]',
  '28-normal': 'font-sans text-[28px] font-normal leading-[33.89px]',
  '28-medium': 'font-sans text-[28px] font-medium leading-[33.89px]',
  '28-semibold': 'font-sans text-[28px] font-semibold leading-[33.89px]',
  '28-bold': 'font-sans text-[28px] font-bold leading-[33.89px]',
  '28-extrabold': 'font-sans text-[28px] font-extrabold leading-[33.89px]',

  '32-thin': 'font-sans text-[32px] font-thin leading-[38.73px]',
  '32-light': 'font-sans text-[32px] font-light leading-[38.73px]',
  '32-normal': 'font-sans text-[32px] font-normal leading-[38.73px]',
  '32-medium': 'font-sans text-[32px] font-medium leading-[38.73px]',
  '32-semibold': 'font-sans text-[32px] font-semibold leading-[38.73px]',
  '32-bold': 'font-sans text-[32px] font-bold leading-[38.73px]',
  '32-extrabold': 'font-sans text-[32px] font-extrabold leading-[38.73px]',

  '36-thin': 'font-sans text-[36px] font-thin leading-[43.57px]',
  '36-light': 'font-sans text-[36px] font-light leading-[43.57px]',
  '36-normal': 'font-sans text-[36px] font-normal leading-[43.57px]',
  '36-medium': 'font-sans text-[36px] font-medium leading-[43.57px]',
  '36-semibold': 'font-sans text-[36px] font-semibold leading-[43.57px]',
  '36-bold': 'font-sans text-[36px] font-bold leading-[43.57px]',
  '36-extrabold': 'font-sans text-[36px] font-extrabold leading-[43.57px]',

  '40-thin': 'font-sans text-[40px] font-thin leading-[48.41px]',
  '40-light': 'font-sans text-[40px] font-light leading-[48.41px]',
  '40-normal': 'font-sans text-[40px] font-normal leading-[48.41px]',
  '40-medium': 'font-sans text-[40px] font-medium leading-[48.41px]',
  '40-semibold': 'font-sans text-[40px] font-semibold leading-[48.41px]',
  '40-bold': 'font-sans text-[40px] font-bold leading-[48.41px]',
  '40-extrabold': 'font-sans text-[40px] font-extrabold leading-[48.41px]',

  '48-thin': 'font-sans text-[48px] font-thin leading-[45px]',
  '48-light': 'font-sans text-[48px] font-light leading-[45px]',
  '48-normal': 'font-sans text-[48px] font-normal leading-[45px]',
  '48-medium': 'font-sans text-[48px] font-medium leading-[45px]',
  '48-semibold': 'font-sans text-[48px] font-semibold leading-[45px]',
  '48-bold': 'font-sans text-[48px] font-bold leading-[45px]',
  '48-extrabold': 'font-sans text-[48px] font-extrabold leading-[45px]',

  '64-thin': 'font-sans text-[64px] font-thin leading-[77px]',
  '64-light': 'font-sans text-[64px] font-light leading-[77px]',
  '64-normal': 'font-sans text-[64px] font-normal leading-[77px]',
  '64-medium': 'font-sans text-[64px] font-medium leading-[77px]',
  '64-semibold': 'font-sans text-[64px] font-semibold leading-[77px]',
  '64-bold': 'font-sans text-[64px] font-bold leading-[77px]',
  '64-extrabold': 'font-sans text-[64px] font-extrabold leading-[77px]',
};
