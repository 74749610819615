import { ArrowSwapHorizontal, MoneyRecive, Trade } from 'iconsax-react';
import { memo } from 'react';
import { NavLink } from 'react-router-dom';

import Text from '../Text/Text';

const links = [
  { to: '/market', label: 'Market', icon: <Trade size={16} variant="Bulk" /> },
  {
    to: '/earn',
    label: 'Earn',
    icon: <MoneyRecive className="rotate-180" size={16} variant="Broken" />,
  },
  { to: '/swap', label: 'Swap', icon: <ArrowSwapHorizontal size={16} variant="Broken" /> },
];

const MobileNavigationBar = memo(() => {
  return (
    <div className="pb-[85px] md:hidden">
      <div className="fixed bottom-0 z-10 flex w-full items-center justify-evenly gap-4 border-t border-white/5 bg-[#14151F]/50 py-5 backdrop-blur-lg">
        {links.map((link) => (
          <NavLink
            key={link.label}
            to={link.to}
            className="flex flex-col items-center gap-1 text-white/40 transition duration-300 ease-out aria-[current]:pointer-events-none aria-[current]:text-white"
          >
            {link.icon}
            <Text variant="12-normal" Component="span">
              {link.label}
            </Text>
          </NavLink>
        ))}
      </div>
    </div>
  );
});

export default MobileNavigationBar;
