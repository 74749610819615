import { memo } from 'react';
import Marquee from 'react-fast-marquee';
import useGetPairs from 'src/hooks/networking/useGetPairs';
import { safeNumber } from 'src/utils/safety.utils';

import FormattedNumber from '../FormattedNumber/FormattedNumber';
import Text from '../Text/Text';

const Trending = memo(() => {
  const { pairs } = useGetPairs();
  return (
    <Text variant="14-normal" className="flex flex-row items-center">
      <Marquee speed={40} pauseOnHover play>
        {pairs.map((pair, i) => {
          const delta = 100 * safeNumber(pair.trendingPoolData?.deltaPercent);
          return (
            <div key={i} className="flex items-center gap-2 border-l-4 border-l-red py-2 pe-5 ps-2">
              <Text variant="12-bold">{`#${i + 1}`}</Text>
              <div className="flex">
                <img width={24} src={pair.mintA?.logoURI} />
                <img className="-ml-2" width={24} src={pair.mintB?.logoURI} />
              </div>
              <div>
                <Text Component="span" variant="12-bold">{`${pair.mintA?.symbol} / `}</Text>
                <Text Component="span" variant="12-semibold">
                  {pair.mintB?.symbol}
                </Text>
              </div>

              <Text variant="12-bold" className={delta >= 0 ? 'text-green' : 'text-red'}>
                <FormattedNumber value={delta} signed />
              </Text>
            </div>
          );
        })}
      </Marquee>
    </Text>
  );
});

export default Trending;
