import { APIToken, toDenom } from '@rever22411/amm-commands';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { useEffect } from 'react';
import useGetTokens from 'src/hooks/networking/useGetTokens';
import useGetUserBalances from 'src/hooks/networking/useGetUserBalances';
import { WalletDrawerBalance } from 'src/types/widgets/WalletDrawer.types';
import { compressAddress } from 'src/utils/format.utils';

import useInvalidateQueries from './networking/useInvalidateQueries';
import useWalletPublicKey from './useWalletPublicKey';

export default function useConnectedWallet() {
  const { publicKeyString } = useWalletPublicKey();
  const solanaWallet = useSolanaWallet();

  const { invalidateTokens } = useInvalidateQueries();
  const { tokens } = useGetTokens();

  const wallet = solanaWallet.wallets.find(({ adapter }) => adapter.connected);

  const walletAddress = compressAddress(publicKeyString);

  const { userBalances } = useGetUserBalances();

  const balances: WalletDrawerBalance[] = userBalances.map((balance) => {
    const addressDisplay = compressAddress(balance.address);
    const token = tokens.find(({ address }) => balance.address === address);
    const denom = token ? toDenom(balance.balance, token.decimals) : undefined;
    return { ...balance, addressDisplay, token, denom };
  });

  const copyWalletKey = () => {
    navigator.clipboard.writeText(publicKeyString);
    alert('Copied');
  };

  const copyTokenAddress = (token?: APIToken) => {
    navigator.clipboard.writeText(token?.address || '');
    alert('Copied');
  };

  useEffect(() => {
    invalidateTokens();
  }, [wallet]);

  return { solanaWallet, wallet, walletAddress, balances, copyWalletKey, copyTokenAddress };
}
