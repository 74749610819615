import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import useApi from 'src/networking/api/useApi';
import { atomJwt } from 'src/storage/atoms';
import { safeArray } from 'src/utils/safety.utils';

export default function useGetMe() {
  const jwt = useAtomValue(atomJwt);
  const getMe = useApi('getMe');

  const query = useQuery({
    enabled: !!jwt,
    queryKey: ['me', jwt],
    queryFn: () => getMe({ headers: { Authorization: `Bearer ${jwt}` } }),
  });

  const me = safeArray(query.data);

  return { ...query, me };
}
