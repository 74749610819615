import { AnchorWallet, configDev, setConnection } from '@rever22411/amm-commands';
import { Keypair } from '@solana/web3.js';
import { useEffect } from 'react';
import useGetBondCurves from 'src/hooks/networking/useGetBondCurves';
import useGetMe from 'src/hooks/networking/useGetMe';
import useGetPairs from 'src/hooks/networking/useGetPairs';
import useGetTokens from 'src/hooks/networking/useGetTokens';

export default function StartupRoot() {
  const { me } = useGetMe();
  const { tokens } = useGetTokens();
  const { pairs } = useGetPairs();
  const { curves } = useGetBondCurves();

  useEffect(() => {
    const userKeypair = Keypair.generate();
    setConnection({ publicKey: userKeypair.publicKey } as AnchorWallet, configDev);
  }, []);

  useEffect(() => {
    console.log('me', me);
  }, [me]);

  useEffect(() => {
    console.log('pairs', pairs);
  }, [pairs]);

  useEffect(() => {
    console.log('tokens', tokens);
  }, [tokens]);

  useEffect(() => {
    console.log('curves', curves);
  }, [curves]);

  return <></>;
}
