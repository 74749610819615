import { useQuery } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import useApi from 'src/networking/api/useApi';
import { safeArray, safeString } from 'src/utils/safety.utils';

export default function useGetPairs() {
  const getPairs = useApi('getPairs');

  const query = useQuery({ queryKey: ['pairs'], queryFn: () => getPairs({}) });

  const pairs = safeArray(query.data);

  const pairsByAddress = keyBy(pairs, 'address');

  const pairAddress = safeString(new URLSearchParams(window.location.search).get('pool'));
  const pair = pairs.find(({ address }) => address === pairAddress);

  return { ...query, pairs, pairsByAddress, pairAddress, pair };
}
