// TODO: .env files

import { cluster } from '@rever22411/amm-commands';

export const ENV = 'dev';
export const VERSION = '0.1.0';

export const MOCKS_ALL_DISABLED = true;
export const MOCKS_ALL_ENABLED = false;

export const PROTOCOL = 'https';
export const HOST = 'api.axalia.io';

export const LIMIT_TOKENS = 100;
export const LIMIT_BOND_CURVES = 10;
export const LIMIT_BOND_SWAP_EVENTS = 10;
export const LIMIT_PAIR_TRANSACTIONS = 10;

export const SOLEXPLORER_BY_TX = (tx: string) =>
  `https://explorer.solana.com/tx/${tx}?cluster=${cluster}`;

export const SOLEXPLORER_BY_ADDRESS = (address: string) =>
  `https://explorer.solana.com/address/${address}?cluster=${cluster}`;

export const SOLSCAN_BY_ACCOUNT = (account: string) =>
  `https://solscan.io/account/${account}?cluster=${cluster}`;
