import { AnchorProvider } from '@coral-xyz/anchor';
import { PublicKey } from '@solana/web3.js';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { PriceRangeGraphState } from 'src/types/data.types';
import { AlertProps } from 'src/types/widgets/Alert.types';
import { DataGridPreferences } from 'src/types/widgets/DataGrid.types';

export const atomAlerts = atom<AlertProps[]>([]);

export const atomJwt = atom('');
export const atomProvider = atom<AnchorProvider>();
export const atomPublicKey = atom<PublicKey>();
export const atomWalletDrawerBannerShowed = atom(false);

export const atomPriceRangeGraphState = atom<PriceRangeGraphState>({
  data: [],
  maxVolume: 0,
  currentPrice: 0,
  min24hPrice: 0,
  max24hPrice: 0,
  minVisiblePrice: 0,
  maxVisiblePrice: 0,
  minPriceRange: 0,
  maxPriceRange: 0,
  svgRef: null,
  width: 400,
  height: 200,
});

export const atomPairsGridPreferences = atomWithStorage<DataGridPreferences>(
  'pairsGridPreferences',
  {
    sortKey: '',
    sortedAsc: true,
    filterStarred: false,
    filterTrendUp: false,
    filterStatusUp: false,
    starredRowsKeys: [],
  },
  undefined,
  { getOnInit: true }
);

export const atomTransactionsGridPreferences = atomWithStorage<DataGridPreferences>(
  'transactionsGridPreferences',
  {
    sortKey: '',
    sortedAsc: true,
    filterStarred: false,
    filterTrendUp: false,
    filterStatusUp: false,
    starredRowsKeys: [],
  },
  undefined,
  { getOnInit: true }
);
