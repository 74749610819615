import { ArrowSwapHorizontal, CloudAdd, ExportSquare, Verify } from 'iconsax-react';
import { memo } from 'react';
import { SOLEXPLORER_BY_TX } from 'src/config';
import { AlertProps } from 'src/types/widgets/Alert.types';

import FormattedNumber from '../../FormattedNumber/FormattedNumber';
import Text from '../../Text/Text';

const AlertSuccess = memo<AlertProps>(({ success }) => {
  return (
    success && (
      <div className="flex items-center gap-4">
        <Verify size={54} className="text-_B5FFEA" variant="Bulk" />
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-3">
            <CloudAdd size={18} className="text-_B5FFEA" variant="Linear" />
            <Text variant="14-semibold" className="text-_B5FFEACC">
              {success.title}
            </Text>
          </div>
          <div className="flex items-center gap-1">
            <Text variant="14-normal" className="text-_B5FFEACC">
              {'Transaction Details'}
            </Text>
            <a href={SOLEXPLORER_BY_TX(success.tx)} target="_blank" rel="noreferrer">
              <ExportSquare size={14} className="cursor-pointer text-_B5FFEACC" />
            </a>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <Text variant="14-medium" className="text-_B5FFEACC">
                <FormattedNumber
                  value={success.amountA}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </Text>
              <img src={success.logoUriA} className="size-6 rounded-full" />
            </div>
            <ArrowSwapHorizontal
              size={18}
              className="-scale-x-100 text-_B5FFEA"
              variant="TwoTone"
            />
            <div className="flex items-center gap-2">
              <img src={success.logoUriB} className="size-6 rounded-full" />
              <Text variant="14-medium" className="text-_B5FFEACC">
                <FormattedNumber
                  value={success.amountB}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </Text>
            </div>
          </div>
        </div>
      </div>
    )
  );
});

export default AlertSuccess;
