import { useAnchorWallet } from '@solana/wallet-adapter-react';

export default function useWalletPublicKey() {
  const wallet = useAnchorWallet();

  const publicKey = wallet?.publicKey;
  const publicKeyString = publicKey?.toString() || '';

  return { publicKey, publicKeyString };
}
