import { HOST, PROTOCOL } from 'src/config';
import { ApiId } from 'src/types/networking/api.types';

export default function useApiConfig(apiId: ApiId) {
  const apiDefaultConfig = {
    protocol: PROTOCOL,
    host: HOST,
    headers: { 'Content-Type': 'application/json' },
    queryParams: {},
    pathParams: {},
    body: {},
  };

  const apiConfig = {
    getMe: { method: 'GET', path: '/me' },
    getTokens: { method: 'GET', path: '/tokens' },
    getPairs: { method: 'GET', path: '/pairs' },
    getPairCandles: { method: 'GET', path: '/pairs/${pairAddress}/candles' },
    getPairTransactions: { method: 'GET', path: '/pairs/${pairAddress}/transactions' },
    getPairVolumes: { method: 'GET', path: '/pairs/${pairAddress}/volumes' },
    getBondCurves: { method: 'GET', path: '/bond-curves' },
    getBondCurveCandles: { method: 'GET', path: '/bond-curves/${curveAddress}/candles' },
    getBondSwapEvents: { method: 'GET', path: '/bond-swap-events' },
    postToken: { method: 'POST', path: '/tokens' },
    postPair: { method: 'POST', path: '/pairs' },
    getStatistics: { method: 'GET', path: '/statistics' },
    getStreamings: { method: 'GET', path: '/streamings' },
    postStartStreaming: { method: 'POST', path: '/streamings' },
    postStopStreaming: { method: 'POST', path: '/streamings/${videoId}/stop' },
  };

  return { ...apiDefaultConfig, ...apiConfig[apiId] };
}
