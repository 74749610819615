import { Copy, LogoutCurve } from 'iconsax-react';
import { memo } from 'react';
import { WalletCardProps } from 'src/types/widgets/WalletCard.types';

import Text from '../Text/Text';

const WalletCard = memo<WalletCardProps>(({ connectedWallet, onClick, onLogout }) => {
  const { wallet, walletAddress, copyWalletKey } = connectedWallet;
  return (
    wallet && (
      <div className="flex min-w-[200px] items-center justify-between rounded-md p-3 pr-[13px] hover:bg-card">
        <div className="flex items-center" onClick={onClick}>
          <img src={wallet.adapter.icon} alt={wallet.adapter.name} className="size-[22px]" />
          <Text variant="16-bold" className="ml-4 flex-1 text-_B7B7B9">
            {walletAddress}
          </Text>
          <Copy
            variant="Bold"
            size={16}
            className="ml-2 cursor-pointer text-_B7B7B9"
            onClick={(event) => {
              copyWalletKey();
              event.stopPropagation();
            }}
          />
        </div>
        {onLogout && (
          <LogoutCurve
            size={24}
            variant="Outline"
            className="rotate-180 cursor-pointer text-_B7B7B9"
            onClick={onLogout}
          />
        )}
      </div>
    )
  );
});

export default WalletCard;
