import '../WalletDrawer.scss';

import { Add } from 'iconsax-react';
import { memo } from 'react';
import { WalletDrawerNotificationAlertProps } from 'src/types/widgets/WalletDrawer.types';
import Button from 'src/ui/widgets/Button/Button';

import Text from '../../Text/Text';
import { todo } from '../useWalletDrawer';

const WalletDrawerNotificationAlert = memo<WalletDrawerNotificationAlertProps>((props) => {
  const { alert } = props;
  return (
    <div className="mt-4 rounded-md bg-card p-4">
      <div className="flex items-start">
        <div className="mr-2 flex-1">
          <Text variant="12-medium">{alert.date}</Text>
          <div className="mt-2 flex items-center">
            <Text variant="16-semibold">{alert.title}</Text>
            {alert.new && (
              <Text variant="12-semibold" className="ml-2 text-green">
                {'New!'}
              </Text>
            )}
          </div>
        </div>
        <Add size={24} onClick={todo} className="rotate-45 text-_B7B7B9" />
      </div>
      <Text variant="12-medium" className="mt-2">
        {alert.description}
      </Text>

      {alert.onAccept && (
        <Button
          onClick={alert.onAccept}
          className="mr-4 mt-4 rounded-md bg-green px-4 py-1.5 text-_292D32"
        >
          {'ACCEPT'}
        </Button>
      )}
      {alert.onRefuse && (
        <Button
          onClick={alert.onRefuse}
          className="mt-4 rounded-md bg-transparent px-4 py-1.5 text-white"
        >
          {'REFUSE'}
        </Button>
      )}

      {alert.onReadMore && (
        <div className="mt-4">
          <Button
            onClick={alert.onReadMore}
            className="mr-4 rounded-md bg-white px-4 py-1.5 text-_292D32"
          >
            {'Read more!'}
          </Button>
        </div>
      )}
    </div>
  );
});

export default WalletDrawerNotificationAlert;
