import { useState } from 'react';

export default function useBoolean(
  initialState = false
): [boolean, { on: () => void; off: () => void; toggle: () => void }] {
  const [state, setState_] = useState(initialState);

  const setState = {
    on: () => setState_(true),
    off: () => setState_(false),
    toggle: () => setState_((x) => !x),
  };

  return [state, setState];
}
