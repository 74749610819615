import { useMediaQuery } from '@uidotdev/usehooks';
import { Breakpoint } from 'src/types/commons.types';

export default function useResponsive() {
  const lowerThanXs = useMediaQuery('(max-width : 639px)');
  const lowerThanSm = useMediaQuery('(max-width : 767px)');
  const lowerThanMd = useMediaQuery('(max-width : 1023px)');
  const lowerThanLg = useMediaQuery('(max-width: 1279px)');
  const lowerThanXl = useMediaQuery('(max-width: 1535px)');

  const upperThanSm = useMediaQuery('(min-width: 640px)');
  const upperThanMd = useMediaQuery('(min-width: 768px)');
  const upperThanLg = useMediaQuery('(min-width: 1024px)');
  const upperThanXl = useMediaQuery('(min-width: 1280px)');
  const upperThan2xl = useMediaQuery('(min-width: 1536px)');

  const lowerThan: Record<Breakpoint, boolean> = {
    xs: lowerThanXs,
    sm: lowerThanSm,
    md: lowerThanMd,
    lg: lowerThanLg,
    xl: lowerThanXl,
    '2xl': true,
  };

  const upperThan: Record<Breakpoint, boolean> = {
    xs: true,
    sm: upperThanSm,
    md: upperThanMd,
    lg: upperThanLg,
    xl: upperThanXl,
    '2xl': upperThan2xl,
  };

  return { lowerThan, upperThan };
}
