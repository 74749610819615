import { ButtonProps, ButtonVariant, ButtonVariantProps } from 'src/types/widgets/Button.types';

export const ButtonVariants: Record<ButtonVariant, ButtonVariantProps> = {
  base: {
    className:
      'bg-accent2 hover:bg-accent-btn focus:bg-accent-btn/80 focus:shadow-accent-btn text-text-accent disabled:bg-accent-btn/40 rounded-full px-4 py-2 font-semibold transition-all',
  },
  red: {
    className:
      'border-[0.5px] border-red/70 bg-red/40 px-4 py-2 rounded font-semibold transition-all',
  },
  green: {
    className:
      'border-[0.5px] border-green/70 bg-green/40 px-4 py-2 rounded font-semibold transition-all',
  },
  violet: {
    className:
      'border-[0.5px] border-blue bg-blue-50 px-4 py-2 rounded font-semibold transition-all',
  },
  gradient: {
    className: 'w-full justify-between rounded px-4 py-2 font-semibold transition-all',
    style: {
      background: 'linear-gradient(270deg, #0A746B 0%, #73260F 100%)',
    },
    renderChildren: (children: ButtonProps['children']) => (
      <span
        style={{
          background: 'linear-gradient(270deg, #43F4F3 0%, #FF9742 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundClip: 'text',
        }}
      >
        {children}
      </span>
    ),
  },
  transparent: {
    className:
      'row flex items-center gap-1 rounded border border-white/15 bg-white/5 px-3 py-2 text-[14px] leading-none text-white/50 transition-all hover:bg-white/10 hover:text-white/70',
  },
};
