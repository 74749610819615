import clsx from 'clsx';
import { Add } from 'iconsax-react';
import { memo } from 'react';
import { AlertProps } from 'src/types/widgets/Alert.types';

import BgBoxes from '../BgBoxes/BgBoxes';
import AlertBody from './components/AlertBody';

const Alert = memo<AlertProps>((props) => {
  const { variant, animated, className, onClose } = props;

  const classes = {
    info: 'bg-_3557A280 border-_4468BA',
    success: 'border-green/70 bg-green/40',
    warning: 'bg-_FF835680 border-_FF946E',
    error: 'border-red/70 bg-red/40',
  };

  return (
    <div className="rounded bg-background">
      <div
        className={clsx(
          'relative max-w-[480px] overflow-hidden rounded border px-6 py-4',
          classes[variant],
          className
        )}
      >
        {onClose && (
          <Add
            size={24}
            onClick={onClose}
            className="absolute right-1.5 top-1.5 z-10 rotate-45 cursor-pointer text-white/80"
          />
        )}
        <AlertBody {...props} />
        <BgBoxes
          className="absolute bottom-0 right-0 size-[200px] opacity-20"
          isAnimated={animated}
        />
      </div>
    </div>
  );
});

export default Alert;
