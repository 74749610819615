import '../WalletDrawer.scss';

import clsx from 'clsx';
import { Eye, EyeSlash } from 'iconsax-react';
import { memo } from 'react';
import useBoolean from 'src/hooks/useBoolean';
import { WalletDrawerPortfolioBalancesProps } from 'src/types/widgets/WalletDrawer.types';

import Text from '../../Text/Text';
import WalletDrawerPortfolioBalance from './WalletDrawerPortfolioBalance';

const WalletDrawerPortfolioBalances = memo<WalletDrawerPortfolioBalancesProps>((props) => {
  const { connectedWallet } = props;
  const { balances } = connectedWallet;
  const [visibleAmount, setVisibleAmount] = useBoolean();
  return (
    <>
      <div className="flex items-center justify-between">
        <Text variant="16-semibold" className="text-_73747A">
          {'Balances'}
        </Text>
        <div className="flex items-center">
          <Text
            variant="20-medium"
            className={clsx(
              'select-none',
              visibleAmount ? 'text-_B7B7B9' : 'text-transparent [text-shadow:0_0_10px_white]'
            )}
          >
            {'$15.484,48'}
          </Text>
          {visibleAmount ? (
            <EyeSlash
              size={20}
              variant="Outline"
              className="ml-2 cursor-pointer text-_B7B7B9"
              onClick={setVisibleAmount.toggle}
            />
          ) : (
            <Eye
              size={20}
              variant="Outline"
              className="ml-2 cursor-pointer text-_B7B7B9"
              onClick={setVisibleAmount.toggle}
            />
          )}
        </div>
      </div>
      <div className="mt-4 rounded-md border border-white/10 bg-_0F11174D px-3">
        {balances.map((balance, i) => (
          <WalletDrawerPortfolioBalance {...props} key={i} balance={balance} />
        ))}
      </div>
    </>
  );
});

export default WalletDrawerPortfolioBalances;
