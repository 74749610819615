import { SOLToken } from '@rever22411/amm-commands';
import { useQuery } from '@tanstack/react-query';
import useApi from 'src/networking/api/useApi';
import { safeArray } from 'src/utils/safety.utils';

export default function useGetTokens() {
  const getTokens = useApi('getTokens');

  const query = useQuery({ queryKey: ['tokens'], queryFn: () => getTokens({}) });

  const tokens = safeArray(query.data);

  // TODO const tokenSol = tokens.find(({ address }) => address === SOLToken.address);
  const tokenSol = tokens.find(({ symbol }) => symbol === SOLToken.symbol);

  return { ...query, tokens, tokenSol };
}
