import '../WalletDrawer.scss';

import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import clsx from 'clsx';
import { Add } from 'iconsax-react';
import { memo } from 'react';
import { WalletDrawerPortfolioProps } from 'src/types/widgets/WalletDrawer.types';
import Button from 'src/ui/widgets/Button/Button';
import WalletCard from 'src/ui/widgets/WalletCard/WalletCard';

import Text from '../../Text/Text';
import { todo } from '../useWalletDrawer';
import WalletDrawerPortfolioBalances from './WalletDrawerPortfolioBalances';

const WalletDrawerPortfolio = memo<WalletDrawerPortfolioProps>((props) => {
  const { connectedWallet } = props;
  const { solanaWallet } = connectedWallet;
  return (
    <>
      {!props.bannerShowed && (
        <div className="mt-8">
          <div className="bg-bottom-right overflow-hidden rounded-md border border-green/50 bg-green/40 bg-[url('/images/bgBanner.svg')] bg-right-bottom bg-no-repeat p-4">
            <div className="flex items-start justify-between">
              <div>
                <Text variant="12-normal">{'Your Wallet now has SuperPowers!'}</Text>
                <Text variant="16-semibold" className="mt-1">
                  {'It can connect to *****!'}
                </Text>
              </div>
              <Add
                size={24}
                onClick={props.hideBanner}
                className="rotate-45 cursor-pointer text-_CEE1E0"
              />
            </div>
            <Text variant="14-light" className="mt-2">
              {
                'Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet!'
              }
            </Text>
            <Button onClick={todo} className="mt-5 w-full rounded-md bg-white text-black">
              {'Read the detailed feature!'}
            </Button>
          </div>
        </div>
      )}
      {solanaWallet.connected && (
        <div className="mt-8">{<WalletDrawerPortfolioBalances {...props} />}</div>
      )}
      <div className="mt-8">
        <Text variant="16-semibold" className="text-_73747A">
          {'Wallets'}
        </Text>
        <div className="mt-4 rounded-md border border-white/10 bg-_0F11174D p-3">
          <WalletCard connectedWallet={connectedWallet} onLogout={solanaWallet.disconnect} />
          <div className={clsx('add-wallet-wrapper', solanaWallet.connected && 'mt-4')}>
            <WalletModalProvider>
              <WalletMultiButton disabled={solanaWallet.connected}>
                <Add size={24} className="mr-2 text-_B7B7B9" />
                <p>{'Add Wallet'}</p>
              </WalletMultiButton>
            </WalletModalProvider>
          </div>
        </div>
      </div>
    </>
  );
});

export default WalletDrawerPortfolio;
