import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getTypedKeys } from 'src/utils/basic.utils';

import en from './locales/en.json';
import it from './locales/it.json';

const resources = { en, it };

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: 'en';
    resources: typeof resources;
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      en: { translation: en },
      it: { translation: it },
    },
    interpolation: {
      escapeValue: false, // React already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export const availableLanguages = getTypedKeys(resources);

export function getLanguage() {
  return i18n.language.slice(0, 2).toLowerCase();
}

export function getCountry() {
  return getLanguage().toUpperCase();
}

export type Language = ReturnType<typeof getLanguage>;

export default i18n;
