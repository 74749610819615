import useAlerts from 'src/hooks/useAlerts';
import Alert from 'src/ui/widgets/Alert/Alert';
import { safeNumber } from 'src/utils/safety.utils';

export default function AlertsRoot() {
  const { alerts, removeAlert } = useAlerts();
  return (
    <div className="fixed bottom-[20px] right-[20px] z-30 flex max-w-[calc(100vw-40px)] flex-col gap-[20px]">
      {alerts.map((alert, i) => (
        <Alert
          key={i}
          className="min-w-[33vw]"
          onClose={() => removeAlert(safeNumber(alert.timestamp))}
          {...alert}
        />
      ))}
    </div>
  );
}
