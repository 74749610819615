import clsx from 'clsx/lite';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { LogoProps } from 'src/types/widgets/Logo.types';

const Logo = memo<LogoProps>(({ className }) => (
  <Link to="/" className={clsx('text-accent1 flex items-center gap-2 no-underline', className)}>
    LOGO
  </Link>
));

export default Logo;
