import { ButtonHTMLAttributes, memo } from 'react';

const ButtonWallet = memo<ButtonHTMLAttributes<HTMLButtonElement>>((props) => {
  return (
    <button {...props}>
      <div className="backdrop-contrast-90 relative inline-block rounded-[10px] bg-gradient-to-r from-[#313860] via-[#573D63] to-[#74434B] p-[6px] bg-blend-overlay shadow-[inset_0_0_0_1px_rgba(255,255,255,0.2)] hover:saturate-150">
        <div className="relative z-20 inline-flex items-center gap-3 rounded-[4px] bg-[#14151F] bg-opacity-90 px-[16px] py-[8px] font-space text-[14px] text-white">
          {props.children}
        </div>
        <div className=" absolute left-0 top-0 z-10 inline-block h-[50px] w-fill-available bg-gradient-to-r from-[#2A62B4] via-[#481A49] to-[#FA7545] blur-[50px]" />
      </div>
    </button>
  );
});

export default ButtonWallet;
