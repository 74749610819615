/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import clsx from 'clsx/lite';
import { memo, useMemo } from 'react';
import useResponsive from 'src/hooks/useResponsive';
import { FormattedNumberProps } from 'src/types/widgets/FormattedNumber.types';

const FormattedNumber = memo<FormattedNumberProps>((props) => {
  const {
    subClassName = '',
    extended = false,
    shorted = false,
    value = null,
    prefix = '',
    suffix = '',
    className,
    signed = false,
    minimumFractionDigits = 0,
    maximumFractionDigits = 3,
    ...otherProps
  } = props;

  const { lowerThan } = useResponsive();

  const numberFormatter = new Intl.NumberFormat(navigator.languages, {
    minimumFractionDigits,
    maximumFractionDigits,
  });

  const formatted = useMemo(() => {
    if (value === null || value === undefined || isNaN(value)) return '-';
    if (value === 0) return '0';

    if ((lowerThan.md && !extended) || shorted) {
      // Handle billions by putting a B at the end
      if (value > 1000000000) {
        return `${numberFormatter.format(Number((value / 10 ** 9).toFixed(2)))} B`;
      }
      // Handle millions by putting an M at the end
      if (value > 1000000) {
        return `${numberFormatter.format(Number((value / 10 ** 6).toFixed(2)))} M`;
      }
      // Handle thousands by putting an M at the end
      if (value > 1000) {
        return `${numberFormatter.format(Number((value / 1000).toFixed(2)))} K`;
      }
      if (value < 1) {
        // expand all decimals to avoid scientific notation
        const intled = value.toFixed(30);
        const zeros = intled.substring(2).match(/0+/)?.[0];
        if (zeros && zeros.length > 2) {
          const [pre, post] = intled.split(zeros);
          return (
            <>
              {pre}0<div className={clsx('self-end text-[70%]', subClassName)}>{zeros.length}</div>
              {post.substring(0, 2)}
            </>
          );
        }
        return intled;
      }
    }
    // Handle the rest of the numbers by falling back to the default behavior
    return numberFormatter.format(Number(value));
  }, [value, subClassName, extended, lowerThan.md]);

  return (
    <span
      style={{ flexFlow: 'nowrap' }}
      className={clsx('flex h-fit min-w-0 flex-nowrap items-center whitespace-nowrap', className)}
      {...otherProps}
    >
      {prefix} {signed && Number(value) >= 0 && '+'}
      {formatted} {suffix}
    </span>
  );
});

export default FormattedNumber;
